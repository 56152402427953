import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Healthcare Design Agency`,
    text: "Healthcare UX Design",
    content: "Our healthcare design agency specializes in innovative, patient-centered solutions that enhance the healthcare experience. Our experts have a deep understanding of the healthcare industry and create functional and efficient custom-design solutions.",
    list: [
      'Experienced Professional Team',
      'Patient-Centered Approach',
      'Innovative Design Solutions'
    ]
  };
  const ourData = {
    title: `Client Insights On Our <span class='h1-span'>Healthcare Design Services</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Leverage Growth With Our <span class='h1-span'>Healthcare UX Designs</span>",
    content: [
      {
        title: "7+ Yrs of Healthcare Expertise",
        text: "Having worked for over seven years in healthcare design, we possess industry-specific knowledge to deliver solutions that prioritize patient well-being and operational efficiency.",
      },
      {
        title: "150+ Healthcare Projects",
        text: "Our healthcare design agency has completed 150+ projects and transformed healthcare spaces, showcasing our commitment to excellence in healthcare design. ",
      },
      {
        title: "90% Client Satisfaction",
        text: "Our healthcare design services have reported over 90% client satisfaction, highlighting our dedication to understanding and exceeding client expectations in the healthcare space.",
      },
      {
        title: "95% Repeat Healthcare Clients",
        text: "We value long-term partnerships and are dedicated to exceeding client expectations with each new project, which has helped us get 95% repeat healthcare clients.",
      },
    ],
  };
  const commonContent1 = {
    title: "Discover Excellence With Our Healthcare Design Agency",
    content: [
      {
        title: "Healthcare Expertise",
        text: "We have the expertise to tackle the unique challenges and complexities of the healthcare industry and deliver solutions that optimize functionality and overall patient experience.",
      },
      {
        title: "Patient-Centric Approach",
        text: "We prioritize a user-centric approach during our design process and create healthcare UX designs catering to unique patient needs and preferences for improved satisfaction.",
      },
      {
        title: "Operational Efficiency",
        text: "We enhance operational efficiency through strategic layout planning and workflow analysis to minimize bottlenecks and deliver effective and efficient services.",
      },
      {
        title: "Innovative Solutions",
        text: "Our healthcare design agency stays current with new technologies and design concepts to deliver forward-thinking solutions and enable clients to remain at the forefront.",
      },
      {
        title: "Flexibility and Adaptability",
        text: "Our healthcare UX design solutions can easily be modified and scaled to meet the evolving needs of the healthcare industry, ensuring long-term relevance and sustainability.",
      },
      {
        title: "Improved Patient Outcomes",
        text: "We prioritize patient well-being and use an evidence-based approach to increase patient satisfaction, positive clinical outcomes, and overall healthcare experiences.",
      },
    ],
  };
  const Questions = [
    "What are the healthcare design services do you provide?",
    "Can you provide the portfolio of your healthcare design solutions?",
    "What sets you apart from other healthcare design agencies?",
    "What is the timeline for the healthcare design projects you undertake?"
  ];
  const faqData = [
    {
        para: [
            `We offer a comprehensive UI UX design services tailored specifically for the healthcare industry. Our team of experienced healthcare experts collaborate to create intuitive, efficient, and patient-centric digital solutions to enhance the overall healthcare experience.`,
            `Some of the healthcare design services we provide include:`
        ],
        list: [
            `<span><h4 class="inline">Patient Portal Design:</h4></span> We design user-friendly patient portals that allow seamless access to medical records, appointment scheduling and other self-service functionalities, improving patient engagement and satisfaction.`,
            `<span><h4 class="inline">EHR (Electronic Health Record) Interface Design:</h4></span> Our UI UX experts work closely with healthcare providers to design intuitive and efficient EHR interfaces that streamline clinical workflows, minimize data entry errors, and enhance the user experience for medical staff.`,
            `<span><h4 class="inline">Mobile App Development:</h4></span> We develop innovative mobile applications that empower patients and healthcare professionals with features such as remote monitoring, medication management, and secure communication. All are designed with a focus on usability and accessibility.`,
            `<span><h4 class="inline">Telemedicine and Telehealth Solutions:</h4></span> Our UI UX design for telemedicine and telehealth platforms ensures a seamless, user-friendly experience for patients and healthcare providers, facilitating effective virtual care delivery.`,
            `<span><h4 class="inline">Medical Device User Interface Design:</h4></span> Our team collaborates with medical device manufacturers to design intuitive and ergonomic user interfaces that improve the usability and safety of medical equipment, benefiting both healthcare professionals and patients.`,
            `<span><h4 class="inline">Healthcare Website Design:</h4></span> We design and develop visually appealing, responsive, and user-friendly healthcare websites that effectively communicate your organization's brand, services, and values while also facilitating online patient engagement.`
        ]
    },
    {
      para: [
        `Certainly! We have an extensive portfolio showcasing a diverse range of healthcare design solutions. Our portfolio highlights our commitment to excellence, innovation, and patient-centered design. Please <a href="/contact-us/" title="reach out to us" target="_blank">reach out to us</a>, and we'd be delighted to share relevant case studies that demonstrate our expertise and capabilities.`
      ],
    },
    {
      para: [
        `We have a deep industry expertise and specialized knowledge that allow us to understand and address the unique challenges and requirements of healthcare environments effectively.`,
        `Additionally, our patient-centered approach ensures that the needs and preferences of patients are prioritized throughout the design process.`,
        `Furthermore, our commitment to innovation, sustainability, and operational efficiency ensures that our designs are not only aesthetically pleasing but also functional, future-proof, and environmentally responsible.`
      ]
    },
    {
      para: [
        `The timeline for healthcare design projects can vary depending on various factors, including the size, scope, and complexity of the project, as well as our clients requirements. `,
        `Generally, we work closely with clients to develop a realistic project schedule that aligns with their goals and constraints.`,
        `From initial concept development to final implementation, we prioritize efficient project management and clear communication to ensure that projects are completed on time and within budget.`,
        `To get the detailed estimate of the timeline and budget, we would request you <a href="/contact-us/" title="contact us" target="_blank">contact us</a>.`
      ],
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What are the healthcare design services do you provide?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We offer a comprehensive UI UX design services tailored specifically for the healthcare industry. Our team of experienced healthcare experts collaborate to create intuitive, efficient, and patient-centric digital solutions to enhance the overall healthcare experience.
          
          Some of the healthcare design services we provide include:
          
          Patient Portal Design 
          We design user-friendly patient portals that allow seamless access to medical records, appointment scheduling and other self-service functionalities, improving patient engagement and satisfaction.
          EHR (Electronic Health Record) Interface Design
          Our UI UX experts work closely with healthcare providers to design intuitive and efficient EHR interfaces that streamline clinical workflows, minimize data entry errors, and enhance the user experience for medical staff.
          Mobile App Development
          We develop innovative mobile applications that empower patients and healthcare professionals with features such as remote monitoring, medication management, and secure communication. All are designed with a focus on usability and accessibility.
          Telemedicine and Telehealth Solutions
          Our UI UX design for telemedicine and telehealth platforms ensures a seamless, user-friendly experience for patients and healthcare providers, facilitating effective virtual care delivery.
          Medical Device User Interface Design
          Our team collaborates with medical device manufacturers to design intuitive and ergonomic user interfaces that improve the usability and safety of medical equipment, benefiting both healthcare professionals and patients.
          Healthcare Website Design
          We design and develop visually appealing, responsive, and user-friendly healthcare websites that effectively communicate your organization's brand, services, and values while also facilitating online patient engagement."
              }
            },{
              "@type": "Question",
              "name": "Can you provide the portfolio of your healthcare design solutions?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Certainly! We have an extensive portfolio showcasing a diverse range of healthcare design solutions. Our portfolio highlights our commitment to excellence, innovation, and patient-centered design. Please reach out to us, and we'd be delighted to share relevant case studies that demonstrate our expertise and capabilities."
              }
            },{
              "@type": "Question",
              "name": "What sets you apart from other healthcare design agencies?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We have a deep industry expertise and specialized knowledge that allow us to understand and address the unique challenges and requirements of healthcare environments effectively. 
          
          Additionally, our patient-centered approach ensures that the needs and preferences of patients are prioritized throughout the design process.
          Furthermore, our commitment to innovation, sustainability, and operational efficiency ensures that our designs are not only aesthetically pleasing but also functional, future-proof, and environmentally responsible."
              }
            },{
              "@type": "Question",
              "name": "What is the timeline for the healthcare design projects you undertake?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The timeline for healthcare design projects can vary depending on various factors, including the size, scope, and complexity of the project, as well as our clients requirements. 
          
          Generally, we work closely with clients to develop a realistic project schedule that aligns with their goals and constraints. 
          
          From initial concept development to final implementation, we prioritize efficient project management and clear communication to ensure that projects are completed on time and within budget. 
          
          To get the detailed estimate of the timeline and budget, we would request you contact us."
              }
            }]
          }                                                 
        `}
      </script>
    </Helmet>
    <Layout hire="Healthcare Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Healthcare Design Agency | Healthcare UX Design"
    description="Your expert healthcare design agency crafting intuitive, patient-centered solutions. Transform medical experiences with our innovative healthcare UX designs."
  />
);
